<template>
    <div>
        
        <div class="searchbar">
            <el-button type="primary" size="large" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加</el-button>
            <!-- default-expand-all  -->
		  <el-table :data="tableData"   row-key="id" :header-cell-style="{background:'#f9f9f9'}" stripe  :tree-props="{children: 'children', hasChildren: 'hasChildren'} " lazy :load="load">
		   
			<el-table-column prop="catname" label="类别名称" ></el-table-column>
            <el-table-column prop="img" label="图片" width="100">
				<template #default="scope" >
                    <el-image  v-if="scope.row.img" :preview-src-list="[base + scope.row.img]" :src="base + scope.row.img" fit="fill" preview-teleported style="margin-top: 5px;width: 30px;height: 30px;" ></el-image>
				</template> 
			</el-table-column>
            <el-table-column prop="bimg" label="Banner" width="100">
				<template #default="scope" >
                    <el-image  v-if="scope.row.bimg" :preview-src-list="[base + scope.row.bimg]" :src="base + scope.row.bimg" fit="fill" preview-teleported style="margin-top: 5px;width: 30px;height: 30px;" ></el-image>
				</template> 
			</el-table-column>
      <el-table-column prop="id" label="id" ></el-table-column>
      <!-- <el-table-column prop="pid" label="pid" ></el-table-column> -->
		   <el-table-column prop="px" label="排序" width="100">
		      <template #default="scope">
		        <el-input v-model="scope.row.px" placeholder="请输入内容" size="mini" @input="paixu(scope.row)"></el-input>
		      </template>
		    </el-table-column>
		    <el-table-column fixed="right" label="操作">
          <template #default="scope">
              <el-button type="primary" size="mini" icon="Edit" @click="showDialog(2,scope.row)">修改</el-button>
              <el-button type="danger" size="mini" icon="Delete" @click="delData(scope.row)">删除</el-button>
          </template>
		    </el-table-column>
		  </el-table>
      <!-- 添加修改 -->
      <el-dialog v-model="dialog" :title="title" :fullscreen="sf">
        <suo-fang  v-on:customEvent="handleCustomEvent"></suo-fang>
        <el-form :model="form" label-width="120px" size="large">
            <el-form-item label="类名">
                <el-input v-model="form.catname" placeholder="请输入类别名" />
            </el-form-item>
            <!-- <el-form-item label="归属" v-if="parent_show">
                <el-select v-model="form.pid" placeholder="请选择类型">
                    <el-option label="根目录" :value="0" />
                    <el-option v-for="(item,index,key) in tableData" :key="index" :label="item.catname" :value="item.id" />
                </el-select>
            </el-form-item> -->

            <el-form-item label="归属" v-if="parent_show">
              <el-tree-select
                v-model="form.pid"
                :data="parentDate"
                :normalizer="normalizer"
                :render-after-expand="false" check-strictly
                :props="{ value: 'id',label: 'catname'}"
                placeholder="选择上级菜单"
                style="width:100%"
                
              />
            </el-form-item>
           
               

            <el-form-item label="图片:" :label-width="formLabelWidth" size="large" prop="img">
                <div style="display: flex;">
                    <el-upload class="avatar-uploader" :action="upload_img" :show-file-list="false" :data="{ Authorization: token }" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img v-if="form.img" :src="base + form.img" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                    </el-upload>
                    <el-icon v-if="form.img"  @click="delnpic()"><Delete /></el-icon>
                </div>  
            </el-form-item>
            <el-form-item label="Banner图:" :label-width="formLabelWidth" size="large" prop="bimg">
                <div style="display: flex;">
                    <el-upload class="avatar-uploader" :action="upload_img" :show-file-list="false" :data="{ Authorization: token }" :on-success="handleAvatarSuccessb" :before-upload="beforeAvatarUpload">
                    <img v-if="form.bimg" :src="base + form.bimg" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                    </el-upload>
                    <el-icon v-if="form.bimg"  @click="delnpicb()"><Delete /></el-icon>
                </div>  
            </el-form-item>

            <el-form-item label="关键字">
                <el-input v-model="form.keys" placeholder="请输入关键字" />
            </el-form-item>

            <el-form-item label="描述">
                <el-input
                        v-model="form.desc"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        type="textarea"
                        placeholder="请输入描述"
                />
            </el-form-item>
              

            

            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="请选择类型" >
                    <el-option v-for="(item, index) in type" :key="index" :label="item.tname" :value="item.v_id" />
                </el-select>
            </el-form-item>

            <el-form-item label="状态">
                <el-select v-model="form.status" placeholder="请选择状态">
                    <el-option label="开启" :value="1" />
                    <el-option label="关闭" :value="0" />
                </el-select>
            </el-form-item>
            <el-form-item label="跳转">
                <el-input v-model="form.jump_id" placeholder="请输入跳转类ID" />
            </el-form-item>
<!-- 
            <el-form-item label="排序" v-if="src_show">
                <el-input v-model="form.px" placeholder="请输入排序" />
            </el-form-item> -->

           
                <!-- <el-input v-model="form.content" placeholder="请输入内容" /> -->
                <quill-editor 
                    class="ql-editor"
                    v-model:content="form.content" 
                    contentType="html"
                    ref="myQuillEditor"
                    :options="editorOption"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @change="onEditorChange($event)"
                />
           
            
            
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button size="default" @click="dialog = false">取消</el-button>
                <el-button size="default" type="primary" @click="submitForm()">确认</el-button>
            </span>
        </template>
    </el-dialog>

		</div>
    </div>
</template>
<script>
import { toRefs,reactive,onMounted } from "vue";
import { CateLists, CateSave, CateDel, CatePx} from "@/network/cate";
import { TypeList } from "@/network/type";
import { UpPic} from "@/network/index";
import { Quill, QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ImageUploader from "quill-image-uploader";
Quill.register("modules/imageUploader", ImageUploader);
import { ImageDrop } from 'quill-image-drop-module'  //图片拖拽 
Quill.register('modules/imageDrop', ImageDrop)
import BlotFormatter from 'quill-blot-formatter'  //图片缩放
Quill.register('modules/blotFormatter', BlotFormatter)
import htmlEditButton from "quill-html-edit-button"; //源代码编辑
Quill.register({"modules/htmlEditButton": htmlEditButton});

import { ElMessage, ElMessageBox } from "element-plus";
import SuoFang from '@/components/SuoFang.vue';
export default {
    name: "CateList",
    components: {
        QuillEditor,Quill,ImageDrop,SuoFang
    },
    
    setup() {
        onMounted(()=>{
            checkDeviceType();
        });
        const base =(process.env.VUE_APP_PUBLIC_PATH); // 获取基本URL
        const state = reactive({
            sf:false,
            tableData:[],
            parentDate:[],
            dialog : false,
            parent_show : true,
            src_show : true,
            title : "添加",
            type:"",
            form: {
                id:0,
                catname: "",
                pid : 0,
                content : "",
                px: 0,
                status:1,
                img:"",
                bimg:"",
            },
            upload_img: base + "/admin/Index/upload_img",
            token: window.localStorage.getItem("token"),
        });
        TypeList().then((e)=>{
            state.type = e;
        } );

        CateLists().then((e)=>{
            state.tableData = e;
            // console.log(e)
            const menu = { id: 0, catname: '一级主类', children: [] };
            menu.children = e;
            state.parentDate.push(menu);
        } );
        const showDialog = (e,row)=>{
           
            state.dialog = true;
            if(e == 1){
                state.form = {
                  id:0,
                  catname: "",
                  pid : 0,
                  content : "<p>",
                  px: 0,
                  status:1,
                  img:"",
                  bimg:"",
                  keys:"",
                  desc:"",
                  type:"",
                  jump_id:0
                };
                state.title = "添加";
            }else{
                
                state.title = "修改";
                state.form = {
                    id: row.id,
                    catname: row.catname,
                    pid: row.pid,
                    px: row.px,
                    status: row.status,
                    content: row.content,
                    img:row.img,
                    bimg:row.bimg,
                    keys:row.keys,
                    desc:row.desc,
                    type:row.type,
                    jump_id:row.jump_id
                };
                //防止内容为空，编辑器返值错误
                if(!row.content){
                    state.form.content = '<p>'
                };
               
            }
        }
        const submitForm = ()=>{
            CateSave(state.form).then( (e)=>{
                if (e != 1) {
                    ElMessage({ message:'成功', type: "success" });
                    CateLists().then( (e)=>{
                        state.tableData = e;
                        state.dialog = false;
                    } )
                    
                }
            } )
        }
        const delData = (row={})=>{
            ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "删除："+row.catname, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then( ()=>{
                CateDel({id:row.id}).then( (e)=>{
                    if (e != 1) {
                        ElMessage({ message:'成功', type: "success" });
                        CateLists().then( (e)=>{
                          state.tableData = e;
                        } )
                    }
                } )
            } ).catch(() => {
                ElMessage({
                    type: "info",
                    message: "取消删除"
                })
            })
        }
        const paixu =(row)=>{
            console.log(row,'px')
            if (row != 1) {
                    
                    CatePx({id:row.id,value:row.px}).then((e) => {
                        ElMessage({ message:'排序更改成功', type: "success" });  
                        setTimeout(() => {
                            CateLists().then( (e)=>{
                               state.tableData = e;
                            } ) 
                        }, 1000);
                          
                    });
                    
                    
                }
            
        }
        // 管理员token，上传图片接口需要
        const token = window.localStorage.getItem("token");
        //单图片上传
        const beforeAvatarUpload = (file) => {
            const isJPG = file.type === "image/png" || "image/jpg" || "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                ElMessage({ message: "上传头像图片只能是 JPG/PNG/JPEG 格式!", type: "error" });
            }
            if (!isLt2M) {
                ElMessage({ message: "上传头像图片大小不能超过 2MB!", type: "error" });
            }
            return isJPG && isLt2M;
        };
        const handleAvatarSuccess = (response, uploadfile) => {
            
            state.form.img = response.data;
            // state.form.img = URL.createObjectURL(uploadfile.raw);
            // console.log(state.form.img)
        };
        const delnpic =() =>{
            state.form.img = null;
        };
        //banner图上传
        const handleAvatarSuccessb = (response, uploadfile) => {
            state.form.bimg = response.data;
        };
        const delnpicb =() =>{
            state.form.bimg = null;
        };
        //编辑器
        const editorOption = {
            modules: {

                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                    ['blockquote', 'code-block'], // 引用  代码块
                    [{ header: 1 }, { header: 2 }], // 1、2 级标题
                    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                    [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                    [{ indent: '-1' }, { indent: '+1' }], // 缩进
                    [{ direction: 'rtl' }], // 文本方向
                    // [{ size: ['12px', false, '16px', '18px', '20px', '30px'] }], // 字体大小
                    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                    [{ font: [false, 'SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }], // 字体种类
                    [{ align: [] }], // 对齐方式
                    ['clean'], // 清除文本格式
                    ['link', 'image', 'video'], // 链接、图片、视频
                ],
                 // 图片拖拽
                 imageDrop: true,
                 // 图片缩放
                 blotFormatter: {
                    // overlay: {
                    //    style: {
                    //        border: '2px solid red',
                    //    }
                    // },
                    toolbar: {
                    mainClassName: 'blot-formatter__toolbar'
                    }
                },
                 // html源代码编辑
                 htmlEditButton: {
                    debug: true, // logging, default:false
                    msg: "在这里修改HTML代码，点击完成将替换编辑器的内容", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                    okText: "完成", // Text to display in the OK button, default: Ok,
                    cancelText: "取消", // Text to display in the cancel button, default: Cancel
                    buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
                    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                    prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                    editorModules: {} // The default mod
                },

                 //上传图片存服务器
                imageUploader: {
                    upload: (file) => {
                        
                        return new Promise((resolve, reject) => {

                            var formData = new FormData();
                            formData.append('file', file);
                            formData.append('Authorization',token);
                            UpPic(formData).then((e)=>{
                            
                                // console.log(e,'@@')
                                if(e.code != 1 ){
                                    setTimeout(() => {
                                        resolve(base + e);
                                    }, 500);
                                }

                            });
                            // console.log(resolve,"555",file)
                            
                            
                        });
                    }
                },

            },
            
            placeholder: '请输入内容',
        };
        
        
        // 失去焦点事件
        const onEditorBlur = (quill) => {
            console.log('editor blur!', quill);
        };
        // 获得焦点事件
        const onEditorFocus = (quill) => {
            console.log('editor focus!', quill);
        };
        // 准备富文本编辑器
        const onEditorReady = (quill) => {
            console.log('editor ready!', quill);
        };
        // 内容改变事件
        const onEditorChange = ({ quill, html, text }) => {
            console.log('editor change!', quill, html, text);
            content.value = html;
        };
        //接收子组件数据
        const handleCustomEvent = (data) => {
            state.sf = data; // 处理接收到的数据
           
        };
        //查是否手机登陆
        const checkDeviceType =() => {
            const userAgentInfo = navigator.userAgent;
            if (/(Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini)/.test(userAgentInfo)) {
                
                state.sf = true; 

            } else {
                state.sf = false; // 若是移动设备则隐藏该div
            }
        };
        return {
            base,
            ...toRefs(state),
            showDialog,
            submitForm,
            delData,
            beforeAvatarUpload,
            handleAvatarSuccess,
            delnpic,
            handleAvatarSuccessb,
            delnpicb,
            paixu,

            editorOption,
            onEditorBlur,
            onEditorFocus,
            onEditorReady,
            onEditorChange,

            handleCustomEvent

        };
        

    }
       
};

    

</script>
<style lang="scss">
/* 自定义编辑器样式 */
.editor .ql-toolbar {
  border: none; /* 去除工具栏边框 */
}
.ql-editor{
    min-height: 300px;
    height:100%;
}
.editor .ql-container {
  height: auto !important; /* 根据内容高度自动调整编辑区域高度 */
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  text-align: center;
}
</style>